import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Typography from '@material-ui/core/Typography';
import Content, { HTMLContent } from '../components/Content'
import Header from '../components/Header'

export const DefinitionPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <section>
      <article>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <PageContent content={content} />
      </article>
    </section>
  )
}

DefinitionPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const DefinitionPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout
      header={ <Header title="Definition" /> }
      content={
        <DefinitionPageTemplate
          contentComponent={HTMLContent}
          title={post.frontmatter.title}
          content={post.html}
        />
      }
    />
  )
}

DefinitionPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DefinitionPage

export const definitionPageQuery = graphql`
  query DefinitionPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
